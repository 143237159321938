import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Box, Modal } from '@mui/material';
import classNames from 'classnames';

import { styles } from './card-info-dialog.styles';

export interface CardInfoDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: () => void;
  texts: string[];
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 270,
  border: 'none',
  borderRadius: '14px',
  paddingTop: '20px',
  background: 'rgba(37, 37, 37, 0.82)',
  outline: 'none',
};

const CardInfoDialogComponent: FC<CardInfoDialogProps> = ({ classes, onClose, isOpen, texts }) => {
  const content = (
    <div className={classes.viewBlock}>
      <div className={classNames(classes.titleBlock, classes.menuItem)}>
        <div className={classes.title}>{t`Card Service`}</div>
        {texts.map((text) => {
          return <div className={classes.text}>{text}</div>;
        })}
      </div>
      <div
        className={classNames(classes.cancelButton, classes.menuItem)}
        onClick={onClose}
      >{t`OK`}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="card-info-label"
        aria-describedby="card-info-card"
      >
        <Box sx={style}>{content}</Box>
      </Modal>
    </div>
  );
};

export default appWithStyles(styles)(CardInfoDialogComponent);
